import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import Container from '@components/Container'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'
import { css, useTheme } from '@emotion/react'
import { SiteMapQuery } from '@graphql-types'
import SEO from '@components/SEO'

const SiteMapPage = (props: PageProps<SiteMapQuery>) => {
	const { t } = useTranslation()
	const theme = useTheme()

	return (
		<Container title={t('index.site_map')}>
			<SEO title={t('index.site_map')} keywords={[]} />
			<h1>{t('index.site_map')}</h1>

			<div
				css={css`
					text-align: left;
					width: 100%;

					h2 {
						margin-top: 2rem;
						padding-bottom: 1rem;
						border-bottom: solid 1px ${theme.textColor};
					}

					> div {
						display: flex;
						flex-wrap: wrap;
						> a {
							min-width: 300px;
						}
					}
				`}>
				<h2>
					<Link to='/courses/'>{t('courses')}</Link>
				</h2>

				<div>
					<Link to={`/schedules/`}>{t('schedules.title')}</Link>
					<Link to='/courses/MAP/'>{t('layout:micromaster')}</Link>
					{props.data.flexCourses.nodes.map((course, i) => (
						<Link
							to={`/courses/FLEX/classes/${course.code}/`}
							key={i}>
							{course.name}
						</Link>
					))}
					<Link to={`/free/tecky-code/`}>
						{t('layout:tecky_code')}
					</Link>
					<Link to={`/free/videos/`}>{t('tecky_videos')}</Link>
					{props.data.proCourses.nodes.map((course, i) => (
						<Link to={`/courses/${course.code}/`} key={i}>
							{course.name}
						</Link>
					))}
				</div>

				<h2>
					<Link to='/courses/MAP/'>
						{t('layout:micromaster')} ({t('countries.hk')})
					</Link>
				</h2>

				<div>
					<Link to='/courses/MAP/details/'>
						{t('courses:common.curriculum')}
					</Link>
					<Link to='/courses/MAP/outcomes/'>
						{t('courses:common.outcomes')}
					</Link>
					<Link to='/courses/MAP/career-support/'>
						{t('courses:common.career_support')}
					</Link>
					<Link to='/courses/MAP/faqs/'>
						{t('courses:common.faqs')}
					</Link>
					<Link to='/courses/MAP/instructors/'>
						{t('courses:common.instructor_team')}
					</Link>
				</div>

				<h2>
					<Link to='/uk/courses/MAP/'>
						{t('layout:micromaster')} ({t('countries.uk')})
					</Link>
				</h2>

				<div>
					<Link to='/uk/courses/MAP/details/'>
						{t('courses:common.curriculum')}
					</Link>
					<Link to='/uk/courses/MAP/outcomes/'>
						{t('courses:common.outcomes')}
					</Link>
					<Link to='/uk/courses/MAP/career-support/'>
						{t('courses:common.career_support')}
					</Link>
					<Link to='/uk/courses/MAP/faqs/'>
						{t('courses:common.faqs')}
					</Link>
					<Link to='/uk/courses/MAP/instructors/'>
						{t('courses:common.instructor_team')}
					</Link>
				</div>

				<h2>
					<Link to='/ca/courses/MAP/'>
						{t('layout:micromaster')} ({t('countries.ca')})
					</Link>
				</h2>

				<div>
					<Link to='/ca/courses/MAP/details/'>
						{t('courses:common.curriculum')}
					</Link>
					<Link to='/ca/courses/MAP/outcomes/'>
						{t('courses:common.outcomes')}
					</Link>
					<Link to='/ca/courses/MAP/career-support/'>
						{t('courses:common.career_support')}
					</Link>
					<Link to='/ca/courses/MAP/faqs/'>
						{t('courses:common.faqs')}
					</Link>
					<Link to='/ca/courses/MAP/instructors/'>
						{t('courses:common.instructor_team')}
					</Link>
				</div>

				{props.data.proCourses.nodes.map((course, i) => (
					<>
						<h2>
							<Link to={`/courses/${course.code}/`} key={i}>
								{course.name}
							</Link>
						</h2>
						<div>
							<Link to={`/courses/${course.code}/details/`}>
								{t('courses:common.curriculum')}
							</Link>
							<Link to={`/courses/${course.code}/instructors/`}>
								{t('courses:common.instructor_team')}
							</Link>
						</div>
					</>
				))}

				<h2>
					<Link to='/events/'>{t('layout:events')}</Link>
				</h2>

				<h2>
					<Link to='/media/'>{t('layout:media')}</Link>
				</h2>

				<h2>
					<Link to='/blog/'>{t('layout:blog')}</Link>
				</h2>

				<h2>
					<Link to='/about-us/'>{t('layout:about_us')}</Link>
				</h2>

				<h2>
					<Link to='/privacy-policy/'>
						{t('index.privacy_policy')}
					</Link>
				</h2>

				<h2>
					<Link to='/accessibility/'>
						{t('index.accessibility_statement')}
					</Link>
				</h2>
			</div>
		</Container>
	)
}

export default SiteMapPage

export const query = graphql`
	query SiteMap($language: String!) {
		...TranslationFragment
		proCourses: allCockpitCoursesCollection(
			filter: {
				_lang: { eq: $language }
				mode: { eq: "night-time" }
				active: { eq: true }
			}
			sort: { fields: order, order: ASC }
		) {
			nodes {
				name
				slogan
				code
				mode
				image {
					path
				}
				imageSharp {
					childImageSharp {
						gatsbyImageData(width: 250, placeholder: BLURRED)
					}
				}
			}
		}
		flexCourses: allCockpitFlexCoursesNewCollection(
			filter: { _lang: { eq: $language }, active: { eq: true } } # sort: { fields: order, order: ASC }
		) {
			nodes {
				name
				code
				iconSharp {
					childImageSharp {
						gatsbyImageData(width: 250, height: 250)
					}
				}
			}
		}
	}
`
